import React from 'react';

const SvgGreen = props => (
  <svg width={18} height={20} {...props}>
    <g fill={props.color} fillRule="evenodd">
      <path d="M6.1 7.697a2.421 2.421 0 0 0-.241-3.416C4.849 3.405.617 2.936.617 2.936s.85 3.943 2.069 5.001a2.421 2.421 0 0 0 3.414-.24M10 9.07c0-.322.102-.694.285-1.074a3.516 3.516 0 0 0 4.431-.498c1.355-1.39 2.386-7.494 2.386-7.494s-5.79.908-7.426 2.587a3.512 3.512 0 0 0-.741 3.758C8.305 7.27 8 8.245 8 9.07V12h2V9.07zM14.674 13H3.325a.998.998 0 0 0-.96 1.275l1.428 5a.998.998 0 0 0 .961.725h8.491a1 1 0 0 0 .962-.725l1.428-5a.997.997 0 0 0-.96-1.275" />
    </g>
  </svg>
);

export default SvgGreen;
