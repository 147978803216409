import React from 'react';

const SvgMoney = props => (
  <svg width={12} height={18} {...props}>
    <path
      d="M11.268 11.184c0 .996-.357 1.857-1.071 2.583-.714.726-1.749 1.191-3.105 1.395v1.998h-2.16v-1.926c-.9-.06-1.752-.213-2.556-.459-.804-.246-1.47-.549-1.998-.909l1.17-2.628a7.57 7.57 0 0 0 1.962.9 7.469 7.469 0 0 0 2.196.342c1.368 0 2.052-.342 2.052-1.026 0-.36-.195-.627-.585-.801-.39-.174-1.017-.357-1.881-.549-.948-.204-1.74-.423-2.376-.657a4 4 0 0 1-1.638-1.125C.822 7.806.594 7.11.594 6.234c0-1.032.372-1.917 1.116-2.655.744-.738 1.818-1.191 3.222-1.359V.24h2.16v1.944c.684.06 1.341.18 1.971.36.63.18 1.191.414 1.683.702L9.648 5.892c-1.2-.648-2.364-.972-3.492-.972-.708 0-1.224.105-1.548.315-.324.21-.486.483-.486.819 0 .336.192.588.576.756.384.168 1.002.342 1.854.522.96.204 1.755.423 2.385.657.63.234 1.176.606 1.638 1.116.462.51.693 1.203.693 2.079z"
      fill="#383838"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgMoney;
