import React from 'react';

const SvgSporty = props => (
  <svg width={20} height={20} {...props}>
    <g fill={props.color} fillRule="evenodd">
      <path d="M16.08 9h3.87a9.932 9.932 0 0 0-1.49-4.316A6.982 6.982 0 0 0 16.08 9M16.08 11a6.988 6.988 0 0 0 2.38 4.317A9.94 9.94 0 0 0 19.95 11h-3.87zM11 9h3.059a8.985 8.985 0 0 1 3.17-5.897A9.959 9.959 0 0 0 11 .051V9zM5.94 9H9V.051a9.959 9.959 0 0 0-6.23 3.052A8.985 8.985 0 0 1 5.94 9M.05 11a9.927 9.927 0 0 0 1.491 4.317A6.997 6.997 0 0 0 3.921 11H.05zM1.541 4.684A9.932 9.932 0 0 0 .05 9h3.87a6.987 6.987 0 0 0-2.379-4.316M14.06 11H11v8.95a9.965 9.965 0 0 0 6.23-3.053A8.985 8.985 0 0 1 14.06 11M9 11H5.941a8.985 8.985 0 0 1-3.17 5.897A9.965 9.965 0 0 0 9 19.95V11z" />
    </g>
  </svg>
);

export default SvgSporty;
