import React from 'react';

const SvgKids = props => (
  <svg width={18} height={18} {...props}>
    <g fill={props.color} fillRule="evenodd">
      <path d="M6.298 6.192A2.995 2.995 0 0 0 8 3.5c0-1.655-1.346-3-3-3s-3 1.345-3 3c0 1.187.699 2.206 1.702 2.692C1.577 6.768 0 8.694 0 11v2a1 1 0 0 0 1 1h1v3a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-3h1a1 1 0 0 0 1-1v-2c0-2.306-1.576-4.232-3.702-4.808M15.754 12.423A2.993 2.993 0 0 0 17 10c0-1.654-1.346-3-3-3s-3 1.346-3 3c0 .998.495 1.877 1.246 2.423C10.92 13.075 10 14.427 10 16v1a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-1c0-1.573-.921-2.925-2.246-3.577" />
    </g>
  </svg>
);

export default SvgKids;
